import React from "react";
import moment from "moment";
import { Card, FormInput, FormRadio, FormTextarea } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import { getDifferenceDays } from "../../../utils/general";

export default class Guarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isKey: null,
      isChargeCable: null,
      isRedKey: null,
      isSecureSRA: null,
      isGuarding: null,
      countKey: "",
      dateDeposit: "",
      costDay: "",
      costToday: "",
      estimateComment: "",
    };
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.updateFolderGuarding
    );
  };

  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: parseInt(value),
      },
      this.updateFolderGuarding
    );
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dataGuarding) !==
        JSON.stringify(this.props.dataGuarding) ||
      JSON.stringify(prevProps.dataIdentification) !==
        JSON.stringify(this.props.dataIdentification)
    ) {
      this.setState({
        isKey: this.props.dataGuarding.isKey,
        isChargeCable: this.props.dataGuarding.isChargeCable,
        isRedKey: this.props.dataGuarding.isRedKey,
        isSecureSRA: this.props.dataGuarding.isSecureSRA,
        isGuarding: this.props.dataGuarding.isGuarding,
        countKey: this.props.dataGuarding.countKey
          ? this.props.dataGuarding.countKey
          : "",
        dateDeposit: this.props.dataGuarding.dateDeposit
          ? this.props.dataGuarding.dateDeposit
          : this.props.dataIdentification &&
            this.props.dataIdentification["Date_Sinistre"]
          ? moment(
              this.props.dataIdentification["Date_Sinistre"][0],
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")
          : "",
        costDay: this.props.dataGuarding.costDay
          ? this.props.dataGuarding.costDay
          : "",
        costToday: this.props.dataGuarding.costToday
          ? this.props.dataGuarding.costToday
          : "",
        estimateComment: this.props.dataGuarding.estimateComment
          ? this.props.dataGuarding.estimateComment
          : "",
      });
    }
  }

  updateFolderGuarding = () => {
    if (this.props.isEdit) {
      let tmpDataGuarding = this.props.dataGuarding;

      tmpDataGuarding.isKey = this.state.isKey;
      tmpDataGuarding.isChargeCable = this.state.isChargeCable;
      tmpDataGuarding.isRedKey = this.state.isRedKey;
      tmpDataGuarding.isSecureSRA = this.state.isSecureSRA;
      tmpDataGuarding.isGuarding = this.state.isGuarding;
      tmpDataGuarding.countKey = this.state.countKey;
      tmpDataGuarding.dateDeposit = this.state.dateDeposit;
      tmpDataGuarding.costDay = this.state.costDay;
      tmpDataGuarding.costToday = this.state.costToday;
      tmpDataGuarding.estimateComment = this.state.estimateComment;

      this.props.updateFolder("dataGuarding", tmpDataGuarding);
    }
  };

  render() {
    const costToday = this.state.costToday
      ? this.state.costToday
      : parseInt(this.state.costDay) &&
        getDifferenceDays(moment(this.state.dateDeposit), moment())
      ? parseInt(this.state.costDay) *
        getDifferenceDays(moment(this.state.dateDeposit), moment())
      : 0;

    return (
      <Row>
        <Col xs={12} md={4} className="d-flex">
          <Card title="Frais de devis" className="mt-10 mt-20-xl w-100">
            <FormTextarea
              placeholder="Commentaire..."
              rows={10}
              name="estimateComment"
              value={
                this.state.estimateComment ? this.state.estimateComment : ""
              }
              onChange={this.handleInputChange}
            />
          </Card>
        </Col>
        <Col xs={12} md={4} className="d-flex">
          <Card title="Clés" className="mt-10 mt-20-xl w-100">
            <p className="m-0 mb-5">Présence des clés</p>
            <FormRadio
              name="isKey"
              value={1}
              checked={this.state.isKey === 1}
              text="Oui"
              onChange={this.handleRadioChange}
            />
            <FormRadio
              className="mb-10"
              name="isKey"
              value={0}
              checked={this.state.isKey === 0}
              text="Non"
              onChange={this.handleRadioChange}
            />
            {this.state.isKey ? (
              <>
                {!this.props.isPrint ? (
                  <FormInput
                    className="mb-10"
                    name="countKey"
                    value={this.state.countKey}
                    onChange={this.handleInputChange}
                    title="Nombre de clés"
                    style={{ maxWidth: "270px" }}
                  />
                ) : (
                  <p>Nombre de clés : {this.state.countKey}</p>
                )}
              </>
            ) : null}

            {this.props.fuel === 4 ? (
              <>
                <p className="m-0 mb-5">Cable de recharge présent</p>
                <FormRadio
                  name="isChargeCable"
                  value={1}
                  checked={this.state.isChargeCable === 1}
                  text="Oui"
                  onChange={this.handleRadioChange}
                />
                <FormRadio
                  className="mb-10"
                  name="isChargeCable"
                  value={0}
                  checked={this.state.isChargeCable === 0}
                  text="Non"
                  onChange={this.handleRadioChange}
                />
              </>
            ) : null}

            {this.props.gender === 1 ? (
              <>
                <p className="m-0 mb-5">Présence clé rouge</p>
                <FormRadio
                  name="isRedKey"
                  value={1}
                  checked={this.state.isRedKey === 1}
                  text="Oui"
                  onChange={this.handleRadioChange}
                />
                <FormRadio
                  className="mb-10"
                  name="isRedKey"
                  value={0}
                  checked={this.state.isRedKey === 0}
                  text="Non"
                  onChange={this.handleRadioChange}
                />

                <p className="m-0 mb-5">Présence antivol SRA</p>
                <FormRadio
                  name="isSecureSRA"
                  value={1}
                  checked={this.state.isSecureSRA === 1}
                  text="Oui"
                  onChange={this.handleRadioChange}
                />
                <FormRadio
                  className="mb-10"
                  name="isSecureSRA"
                  value={0}
                  checked={this.state.isSecureSRA === 0}
                  text="Non"
                  onChange={this.handleRadioChange}
                />
              </>
            ) : null}
          </Card>
        </Col>
        <Col xs={12} md={4} className="d-flex">
          <Card title="Gardiennage" className="mt-10 mt-20-xl w-100">
            {!this.props.isPrint ? (
              <FormInput
                className="mb-10"
                name="dateDeposit"
                value={this.state.dateDeposit}
                onChange={this.handleInputChange}
                title="Date de dépôt du véhicule"
                type="date"
                style={{ maxWidth: "270px" }}
              />
            ) : (
              <p>
                Date de dépôt du véhicule :{" "}
                {this.state.dateDeposit
                  ? moment(this.state.dateDeposit).format("DD/MM/YYYY")
                  : ""}
              </p>
            )}
            <p className="m-0 mb-5">Frais de gardiennage</p>
            <FormRadio
              name="isGuarding"
              value={1}
              checked={this.state.isGuarding === 1}
              text="Oui"
              onChange={this.handleRadioChange}
            />
            <FormRadio
              className="mb-10"
              name="isGuarding"
              value={0}
              checked={this.state.isGuarding === 0}
              text="Non"
              onChange={this.handleRadioChange}
            />
            {this.state.isGuarding ? (
              <>
                {!this.props.isPrint ? (
                  <FormInput
                    className="mb-10"
                    name="costDay"
                    value={this.state.costDay}
                    onChange={this.handleInputChange}
                    title="Coût journalier (en € HT)"
                    style={{ maxWidth: "270px" }}
                  />
                ) : (
                  <p>Coût journalier : {this.state.costDay} € HT</p>
                )}
                {!this.props.isPrint ? (
                  <FormInput
                    className="mb-10"
                    name="costToday"
                    value={costToday}
                    onChange={this.handleInputChange}
                    title="Montant gardiennage à ce jour (en € HT)"
                    style={{ maxWidth: "270px" }}
                  />
                ) : (
                  <p>Montant gardiennage à ce jour : {costToday} € HT</p>
                )}
              </>
            ) : null}
          </Card>
        </Col>
      </Row>
    );
  }
}
